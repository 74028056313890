import 'normalize.css'
import './src/styles/global.scss'

export const shouldUpdateScroll = ({ routerProps }) => {
  const { disableScrollUpdate } = routerProps.location.state || {}

  return !disableScrollUpdate
}

console.log('version: ', process.env.APP_VERSION)
console.log('branch: ', process.env.GATSBY_CMS_CONFIG_BRANCH)
